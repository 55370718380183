import { AppRoutes } from "enums/routes.enum";

export const FOOTER_EXCLUDED_PATHS = [
    AppRoutes.SHARE_NON_LEGAL,
    AppRoutes.SHARE_LEGAL,
    AppRoutes.FORGOT_PASSWORD,
    AppRoutes.NEW_PASSWORD,
    AppRoutes.LOG_IN,
    AppRoutes.SIGN_UP,
    AppRoutes.RESTRICTED_ACCESS,
    AppRoutes.PRIVACY_POLICY,
    AppRoutes.COOKIE_POLICY,
    AppRoutes.TERMS_OF_USE,
    AppRoutes.FIND_DEVICE,
    AppRoutes.LOST_PHONE,
];
