import { AppRoutes } from "enums/routes.enum";
import { ReactComponent as LostPhoneIcon } from "assets/icons/account-menu/lost-phone.svg";
import { ReactComponent as FindByNumberIcon } from "assets/icons/account-menu/by-number.svg";

export const menuList = [
    {
        title: "Location by number",
        path: AppRoutes.FIND_DEVICE,
        icon: <FindByNumberIcon />,
    },
    {
        title: "Find lost phone",
        path: AppRoutes.LOST_PHONE,
        icon: <LostPhoneIcon />,
    },
];
