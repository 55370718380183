export const HOW_IT_WORKS_CARDS = [
    {
        title: "Set up an account",
        subtitle: "You only need a phone number, email, and password.",
    },
    {
        title: "Initiate a location request",
        subtitle: "The service will send a text message to pinpoint the phone's location.",
    },
    {
        title: "Obtain the location",
        subtitle: "The address and GPS coordinates will be displayed on your dashboard.",
    },
];

export const BENEFIT_PARAMETERS = [
    {
        title: "570k+",
        subtitle: "of users who have used our website",
    },
    {
        title: "99%",
        subtitle: "of success in locating the lost phone",
    },
];

export const TIPS = ["Type your message", "Offer an attractive reward", "Enter an alternative phone number", "Enter your email"];
