import axios from "axios";
import { EndpointEnum } from "enums/endpoint.enum";
import { isResponseOk } from "helpers/isResponseOk";
import { GOOGLE_MAP_API_KEY, PROJECT_NAME } from "constants/env.const";
import { ILocation, LocationType, LostPhoneInfo } from "interfaces/location/location.interface";
import { get, post } from "./http.client";

type CreateLocation = Required<Pick<ILocation, "name" | "type">>;

interface CreateFindDevice extends CreateLocation {
    type: LocationType.FIND_DEVICE;
}

interface CreateLostPhone extends CreateLocation {
    type: LocationType.LOST_PHONE;
    lostPhoneInfo: LostPhoneInfo;
}

export const getLocationLink = async (data: CreateFindDevice | CreateLostPhone) => {
    return post(`${EndpointEnum.LOCATIONS}/link`, data);
};

export type UpdateLocationProps = Required<Pick<ILocation, "latitude" | "longitude" | "target" | "details">>;

export const updateLocation = async (id: string, data: UpdateLocationProps) => {
    return post<ILocation>(`${EndpointEnum.LOCATIONS}/${id}`, data);
};

export const getLocations = async (locationType?: ILocation["type"]) => {
    try {
        const response = await get(`${EndpointEnum.LOCATIONS}`, {
            type: locationType,
        });
        if (isResponseOk(response)) {
            return response?.data;
        }
    } catch (e) {
        console.error("error:", e);
    }
};

export interface SendLocationSmsProps {
    locationId: string;
    language: string;
    flowOuter: boolean;
}

export const sendLocationSms = async ({ locationId, language, flowOuter }: SendLocationSmsProps) => {
    return post(`${EndpointEnum.LOCATIONS}/${locationId}/sms`, {
        project: PROJECT_NAME,
        language,
        flowOuter,
    });
};

export const getCoordinatesByCityName = async (cityName: string) => {
    try {
        const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
            params: {
                address: cityName,
                key: GOOGLE_MAP_API_KEY,
            },
        });
        const { results } = response.data;
        if (results && results.length > 0) {
            const { lat, lng } = results[0].geometry.location;
            return { lat, lng };
        } else {
            throw new Error("No results found");
        }
    } catch (error) {
        console.error("Error fetching coordinates:", error);
        throw error;
    }
};
