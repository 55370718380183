import { Box, Stack, SxProps, Typography } from "@mui/material";
import { TelephoneForm } from "components/TelephoneForm";
import { IconTextBadge, WidthContainer } from "components/shared";
import { themeColors } from "constants/colors.const";
import { AppRoutes } from "enums/routes.enum";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { saveToSessionStorage } from "services/storage";

interface Props {
    sx?: SxProps;
    withSecurityBadges?: boolean;
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
}

export const HeroNonLegal: FC<Props> = ({ title, subtitle, sx, withSecurityBadges }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { isMobile } = useDeviceDetect();
    const isLostPhonePage = pathname === AppRoutes.HOME_LOST_PHONE;
    const submitQueryParam = isLostPhonePage ? "lost_phone" : "non_legal";

    const onSubmit = () => saveToSessionStorage("landingType", submitQueryParam);

    return (
        <Box
            sx={{
                position: "relative",
                padding: { xs: "90px 0 110px", sm: "204px 0 140px" },
                background: "url(/images/landing-hero-dark-bg.jpg) center / cover no-repeat",
                ...sx,
            }}
            id="tel-input"
        >
            <WidthContainer>
                <Stack
                    direction="column"
                    sx={{
                        maxWidth: {
                            xs: "100%",
                            sm: "55%",
                        },
                    }}
                >
                    <Typography
                        variant="h1"
                        color={themeColors.text.white}
                        mb="1.25rem"
                        sx={{
                            lineHeight: {
                                xs: "36px",
                                sm: "42px",
                            },
                            fontSize: {
                                xs: "28px",
                                sm: "34px",
                            },
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography variant="title2Regular" color={themeColors.text.grey} mb="2rem">
                        {subtitle}
                    </Typography>
                    <Stack gap="16px" height="170px" direction={isLostPhonePage && !isMobile ? "row" : "column"}>
                        <TelephoneForm
                            redirectPath={`${AppRoutes.SEARCH}?landing=${submitQueryParam}`}
                            buttonProps={isLostPhonePage ? { children: t("Continue") } : undefined}
                            onSubmit={onSubmit}
                        />
                    </Stack>
                    {withSecurityBadges && (
                        <Stack gap="20px" direction="row" sx={{ mt: "4rem" }}>
                            <IconTextBadge
                                imageUrl="/icons/secured-shield.svg"
                                sx={{ borderColor: themeColors.background.primary, "& span": { color: themeColors.background.primary } }}
                                title={t("SECURE")}
                                subtitle={t("SSL ENCRYPTION")}
                            />
                            <IconTextBadge
                                imageUrl="/icons/lock.svg"
                                sx={{ borderColor: themeColors.background.primary, "& span": { color: themeColors.background.primary } }}
                                title="100%"
                                subtitle={t("CONFIDENTIAL")}
                            />
                        </Stack>
                    )}
                </Stack>
            </WidthContainer>
        </Box>
    );
};
