import { SidebarTypes } from "enums/SidebarTypes.enum";
import { AppRoutes } from "enums/routes.enum";
import { isValidUrl } from "helpers/isValidUrl";
import { useAppData } from "providers/RootStoreProvider";
import { RefObject, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDeviceDetect } from "./useDeviceDetect";

export const useHeaderLogic = ({
    sidebarRef,
    sidebarTogglerRef,
    languagePickerRef,
}: {
    sidebarRef: RefObject<HTMLDivElement | null>;
    sidebarTogglerRef: RefObject<HTMLButtonElement | null>;
    languagePickerRef: RefObject<HTMLDivElement | null>;
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isTablet } = useDeviceDetect();
    const { flowOuter } = useAppData();
    const [searchParams] = useSearchParams();
    const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
    const [languagePickerOpen, setLanguagePickerOpen] = useState(false);
    const [sidebar, setSidebar] = useState({
        type: SidebarTypes.Navigation,
        isOpen: false,
    });
    const redirectSearchParam = searchParams.get("redirect");

    const isHomePage = pathname === AppRoutes.HOME;
    const isAccountPage = pathname === AppRoutes.ACCOUNT;
    const isSearchPage = pathname === AppRoutes.SEARCH;
    const isLegalPage = pathname === AppRoutes.COOKIE_POLICY || pathname === AppRoutes.TERMS_OF_USE || pathname === AppRoutes.PRIVACY_POLICY;
    const isPaymentErrorPage = pathname === AppRoutes.DECLINE;
    const isPaymentPage = pathname === AppRoutes.PAYMENT;
    const isLostPhonePage = pathname === AppRoutes.HOME_LOST_PHONE;

    const isPAymentFlowOuter = isPaymentPage && !flowOuter;

    const isCloseButtonShown = (!isHomePage && !isLostPhonePage && !isSearchPage && !isAccountPage && !isPaymentPage) || isPAymentFlowOuter;
    const isMenuTogglerShown = isTablet && (isHomePage || isAccountPage || isSearchPage || isLostPhonePage);
    const renderLoginButton = isHomePage || isAccountPage || isSearchPage || isLostPhonePage;
    const isNavigateBack = isLegalPage || isPaymentErrorPage;
    const disabledLogoRedirect = isAccountPage;

    const handleLogoClick = (e) => {
        if (redirectSearchParam && isValidUrl(redirectSearchParam)) {
            e.preventDefault();
            window.location.href = redirectSearchParam;
        } else if (disabledLogoRedirect) {
            e.preventDefault();
        }
    };

    const toggleSidebar = (type?: SidebarTypes) => {
        if (type) {
            setSidebar({ isOpen: !sidebar.isOpen, type });
        } else {
            setSidebar({ ...sidebar, isOpen: !sidebar.isOpen });
        }
    };

    const handleClickOutsideLanguagePicker = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (languagePickerRef.current && !languagePickerRef.current.contains(target) && !isTablet) {
            setLanguagePickerOpen(false);
        }
    };

    const handleClickOutsideSidebar = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (
            sidebarRef.current &&
            !sidebarRef.current.contains(target) &&
            !sidebarTogglerRef.current?.contains(target) &&
            !languagePickerRef.current?.contains(target)
        ) {
            setSidebar({ ...sidebar, isOpen: false });
        }
    };

    useEffect(() => {
        if (languagePickerOpen) {
            document.addEventListener("click", handleClickOutsideLanguagePicker);

            return () => {
                document.removeEventListener("click", handleClickOutsideLanguagePicker);
            };
        }
    }, [languagePickerOpen]);

    useEffect(() => {
        if (sidebar.isOpen) {
            document.addEventListener("click", handleClickOutsideSidebar);

            return () => {
                document.removeEventListener("click", handleClickOutsideSidebar);
            };
        }
    }, [sidebar.isOpen]);

    const handleLanguagePickerClick = () => {
        if (isTablet) {
            if (sidebar.isOpen && sidebar.type === SidebarTypes.LanguageMenu) {
                setSidebar({ ...sidebar, isOpen: false });
            } else if (sidebar.isOpen && sidebar.type !== SidebarTypes.LanguageMenu) {
                setSidebar({ ...sidebar, type: SidebarTypes.LanguageMenu });
            } else {
                setSidebar({
                    isOpen: true,
                    type: SidebarTypes.LanguageMenu,
                });
            }
        } else {
            setLanguagePickerOpen(!languagePickerOpen);
        }
    };

    const handleCloseButtonClick = () => {
        if (isValidUrl(redirectSearchParam)) {
            if (redirectSearchParam) window.location.href = redirectSearchParam;
        } else {
            if (isPaymentPage) {
                navigate(AppRoutes.ACCOUNT);
            } else if (isNavigateBack) {
                navigate(-1);
            } else {
                navigate(AppRoutes.HOME);
            }
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY > 50) {
                setIsHeaderScrolled(true);
            } else {
                setIsHeaderScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return {
        sidebar,
        isCloseButtonShown,
        isMenuTogglerShown,
        renderLoginButton,
        isHeaderScrolled,
        isHomePage,
        isLostPhonePage,
        isAccountPage,
        isLegalPage,
        isPaymentPage,
        languagePickerOpen,
        handleCloseButtonClick,
        handleLanguagePickerClick,
        toggleSidebar,
        handleLogoClick,
        setSidebar,
        setLanguagePickerOpen,
    };
};
