import { useTranslation } from "react-i18next";
import { themeFonts } from "mui-theme/themeFonts.const";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { getLanguageOptions } from "helpers/language.helpers";
import { useBoolean } from "hooks/useBoolean";
import { FC } from "react";
import { Languages } from "enums/languages.enum";

const StyledLanguageButton = styled(Button)(({ theme }) => ({
    height: "32px",
    width: "calc(50% - .75rem)",
    borderRadius: "4px",
    color: theme.palette.background.default,
    background: "transparent",
    border: `.5px solid ${theme.palette.background.whiteOpacity2}`,
    fontFamily: themeFonts.salara,
    textTransform: "capitalize",
    "&.active": {
        background: "linear-gradient(45deg, rgba(94, 130, 246, 0.2) -0.09%, rgba(134, 94, 246, 0.2) 99.91%)",
        "& .lang": {
            display: "flex",
            alignItems: "center",
            "&::after": {
                content: "url('/icons/green-checked.svg')",
                margin: "3px 0 0 13px",
            },
        },
    },
}));

const LanguageAccordion: FC<{ onSelect: (id: Languages) => void }> = ({ onSelect }) => {
    const { i18n } = useTranslation();
    const [languagesBarOpen, setLanguagesBarOpen] = useBoolean(false);
    return (
        <Box>
            <Stack onClick={setLanguagesBarOpen.toggle} direction="row" justifyContent="space-between" alignItems="center" sx={{ cursor: "pointer" }}>
                <Typography color={themeColors.text.white} textTransform="uppercase">
                    {i18n.language}
                </Typography>
                <img src="/icons/arrow.svg" alt="extend list" style={{ transform: languagesBarOpen ? "rotate(180deg)" : "unset" }} />
            </Stack>
            {languagesBarOpen && (
                <Stack direction="row" flexWrap="wrap" gap="1rem" padding="14px 0">
                    {getLanguageOptions?.map((item) => (
                        <StyledLanguageButton
                            onClick={() => onSelect(item.id)}
                            key={item.id}
                            className={item.id.toLowerCase() === i18n.language.toLowerCase() ? "active" : undefined}
                        >
                            <Typography variant="body2Regular" className="lang">
                                {item.title.toLowerCase()}
                            </Typography>
                        </StyledLanguageButton>
                    ))}
                </Stack>
            )}
        </Box>
    );
};

export default LanguageAccordion;
