import { useEffect, useState } from "react";

export const useScrollEvent = (callback: () => void) => {
    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setHasScrolled(true);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (hasScrolled) {
            callback();
        }
    }, [hasScrolled]);
};
