import { useState } from "react";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import { Image, WidthContainer } from "components/shared";
import { themeColors } from "constants/colors.const";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { scrollToElementById } from "helpers/scrollToElementById";
import { BENEFIT_PARAMETERS, HOW_IT_WORKS_CARDS, TIPS } from "./howItWorksLostPhone.model";

const StyledCardsBlock = styled(Box)({
    borderRadius: "3rem 3rem 0px 0px",
    backgroundColor: themeColors.background.primary,
});

const StyledCard = styled(Stack)(({ theme }) => ({
    background: themeColors.background.white,
    padding: "1rem .5rem",
    width: "calc(33% - 1.3rem)",
    textAlign: "center",
    borderRadius: ".5rem",
    flexShrink: 0,
    boxSizing: "border-box",
    height: "100%",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));

export const HowItWorksLostPhone = () => {
    const { t } = useTranslation();
    const { isMobile } = useDeviceDetect();
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const tryNowClick = () => {
        scrollToElementById("tel-input");
        handleAmpliLoaded(() => ampli.landingTryNowClick({ landing_type: "lost my phone" }));
    };

    return (
        <>
            <StyledCardsBlock mt="-70px" sx={{ zIndex: 2, position: "relative" }}>
                {isMobile ? (
                    <Swiper
                        initialSlide={activeSlideIndex}
                        loop={false}
                        slidesPerView={1.3}
                        spaceBetween={30}
                        modules={[Autoplay]}
                        centeredSlides
                        autoplay={{
                            delay: 2700,
                            disableOnInteraction: false,
                        }}
                        speed={700}
                        onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
                        style={{ padding: "3.5rem 0 2rem" }}
                    >
                        {HOW_IT_WORKS_CARDS.map((card, index) => (
                            <SwiperSlide
                                key={`slideCard-${index}`}
                                style={{
                                    flexShrink: 0,
                                    width: "100%",
                                    height: "80%",
                                    alignSelf: "center",
                                    transform: activeSlideIndex === index ? "scale(1.15)" : "scale(1)",
                                    transition: ".4s",
                                    borderRadius: ".5rem",
                                }}
                            >
                                <StyledCard key={`card-${index}`}>
                                    <Typography variant="h3" color={themeColors.text.black} mb="1rem">
                                        {t(card.title)}
                                    </Typography>
                                    <Typography variant="body2Regular" color={themeColors.text.grey}>
                                        {t(card.subtitle)}
                                    </Typography>
                                </StyledCard>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <WidthContainer style={{ padding: "62px 0" }}>
                        <Stack direction="row" gap="2rem">
                            {HOW_IT_WORKS_CARDS.map((card, index) => (
                                <StyledCard key={`card-${index}`}>
                                    <Typography variant="h3" color={themeColors.text.black} mb="1rem">
                                        {t(card.title)}
                                    </Typography>
                                    <Typography variant="title2Regular" color={themeColors.text.grey}>
                                        {t(card.subtitle)}
                                    </Typography>
                                </StyledCard>
                            ))}
                        </Stack>
                    </WidthContainer>
                )}
            </StyledCardsBlock>
            <Box
                position="relative"
                sx={{
                    background: "url(/images/how-it-works-benefits-bg.jpg) center / cover no-repeat",
                }}
            >
                <WidthContainer style={{ padding: isMobile ? "28px 0 35px" : "65px 0" }}>
                    <Stack direction={isMobile ? "column" : "row"} justifyContent="space-between" alignItems="center">
                        <Typography
                            variant={isMobile ? "h3" : "h1"}
                            color={themeColors.text.whiteOpacity8}
                            sx={{ maxWidth: { xs: "100%", sm: "60%" }, mb: { xs: "2rem", sm: "unset" } }}
                        >
                            {t("We can find your device! Put your trust in our expertise and get your phone back")}{" "}
                            <span style={{ color: themeColors.text.white }}>{t("without any hassle")}!</span>
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap="1rem"
                            sx={{ maxWidth: { xs: "100%", sm: "35%" }, justifyContent: { xs: "space-between", sm: "center" } }}
                        >
                            {BENEFIT_PARAMETERS.map((param, index) => (
                                <>
                                    <Box key={`param-${index}`} flexShrink={0} maxWidth="calc(50% - 2rem)">
                                        <Typography
                                            variant="h1"
                                            lineHeight={1}
                                            sx={{ fontSize: { xs: "48px", sm: "56px" } }}
                                            color={themeColors.text.white}
                                            mb="10px"
                                        >
                                            {t(param.title)}
                                        </Typography>
                                        <Typography
                                            variant="smallRegular"
                                            lineHeight={1}
                                            color={themeColors.text.white}
                                            sx={{ display: "block", lineHeight: "20px" }}
                                        >
                                            {t(param.subtitle)}
                                        </Typography>
                                    </Box>
                                    {index !== BENEFIT_PARAMETERS.length - 1 && (
                                        <Box
                                            sx={{
                                                width: "1px",
                                                height: "4rem",
                                                background: "#d9d9d9",
                                                flexShrink: 0,
                                            }}
                                        />
                                    )}
                                </>
                            ))}
                        </Stack>
                    </Stack>
                </WidthContainer>
            </Box>
            <Box>
                <WidthContainer style={{ padding: isMobile ? "1rem 0 72px" : "83px 0" }}>
                    <Stack direction={isMobile ? "column" : "row"}>
                        <Box width={isMobile ? "100%" : "50%"} flexShrink="0">
                            <Image
                                src={isMobile ? "/images/two-phones-mob.png" : "/images/two-phones.png"}
                                alt="phone tips"
                                style={
                                    isMobile
                                        ? {
                                              maxWidth: "unset",
                                              width: "110%",
                                              objectFit: "cover",
                                          }
                                        : {
                                              objectFit: "contain",
                                          }
                                }
                            />
                        </Box>
                        <Stack sx={{ mt: { xs: "-6rem", sm: "unset" } }} id="how-it-works">
                            <Typography variant="h3" fontSize={isMobile ? "28px" : "36px"} mb="44px">
                                {t("Here are some essential tips to get you started on your way to success!")}
                            </Typography>
                            <Stack mb={isMobile ? "1rem" : "3rem"}>
                                {TIPS.map((tip, index) => (
                                    <Stack key={`tip-${index}`} direction="row" alignItems="center" gap="12px" mb="36px">
                                        <Typography
                                            variant="h1"
                                            fontSize="40px"
                                            sx={{
                                                background: "linear-gradient(to right, #305945, #67BF95)",
                                                WebkitBackgroundClip: "text",
                                                WebkitTextFillColor: "transparent",
                                            }}
                                        >
                                            {index < 9 ? `0${index + 1}` : index + 1}
                                        </Typography>
                                        <Typography variant="title2Regular" fontSize="28px" color={themeColors.text.tertiary}>
                                            {t(tip)}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                            <Button onClick={tryNowClick} variant="themePrimary" sx={{ width: { xs: "100%", sm: "350px" }, whiteSpace: "nowrap" }}>
                                {t("TRY NOW")}
                            </Button>
                        </Stack>
                    </Stack>
                </WidthContainer>
            </Box>
        </>
    );
};
