import { Button, styled, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { themeColors } from "constants/colors.const";
import { useTranslation } from "react-i18next";
import { getLanguageOptions, saveLanguage } from "helpers/language.helpers";
import { Languages } from "enums/languages.enum";
import { MouseEvent, useState } from "react";
import { themeFonts } from "mui-theme/themeFonts.const";

const StyledLanguagePicker = styled(Menu)(({ theme }) => ({
    "& .MuiMenu-paper": {
        borderRadius: "10px",
    },
    "& .MuiMenu-list": {
        background: theme.palette.background.secondary,
    },
}));

const StyledLanguageOption = styled(MenuItem)(({ theme }) => ({
    textTransform: "uppercase",
    justifyContent: "center",
    cursor: "pointer",
    color: themeColors.text.grey,
    textAlign: "center",
    fontFamily: themeFonts.salara,
    fontSize: "16px",
    fontWeight: 700,
    "&.active": {
        color: theme.palette.brandColors.brandGreen,
    },
    "&.Mui-focusVisible": {
        color: theme.palette.brandColors.brandGreen,
    },
    "&:hover": {
        color: theme.palette.text.secondary,
    },
}));

export const LanguagePicker = () => {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = !!anchorEl;

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleLanguagePick = (id: Languages) => {
        saveLanguage(id.toUpperCase());
        handleClose();
    };

    return (
        <>
            <Button
                variant="outlined"
                sx={(theme) => ({
                    padding: 0,
                    width: "44px",
                    height: "44px",
                    borderRadius: "10px",
                    minWidth: "44px",
                    fontWeight: 700,
                    color: open ? theme.palette.brandColors.brandGreen : theme.palette.text.secondary,
                    backgroundColor: open ? theme.palette.text.secondary : "none",
                })}
                onClick={handleClick}
            >
                <Typography variant="body1CaptionBold">{i18n.language}</Typography>
            </Button>
            <StyledLanguagePicker
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {getLanguageOptions?.map((lang) => (
                    <StyledLanguageOption
                        className={lang.id.toLowerCase() === i18n.language.toLowerCase() ? "active" : undefined}
                        key={lang.id}
                        onClick={() => handleLanguagePick(lang.id)}
                    >
                        {lang.title}
                    </StyledLanguageOption>
                ))}
            </StyledLanguagePicker>
        </>
    );
};
