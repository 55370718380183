import { HeroNonLegal } from "./sections/HeroNonLegal";
import { HowItWorksLostPhone } from "./sections/HowItWorksLostPhone";
import { FeedbacksSectionNonLegal } from "./sections/FeedbacksSection/FeedbacksSectionNonLegal";
import { useLandingEvents } from "hooks/useLandingEvents";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

export const HomeLostPhone = () => {
    const { t } = useTranslation();
    useLandingEvents({ landing_type: "lost my phone" });

    return (
        <Box
            sx={{
                maxWidth: "100%",
                margin: 0,
                padding: 0,
                overflowX: "hidden",
            }}
        >
            <HeroNonLegal
                sx={{ background: "url(/images/lost-phone-hero.jpg) center / cover no-repeat" }}
                title={t("An effective method to locate your lost phone")}
                subtitle={t("Request the return of the phone by sending a text or voice message to your device")}
            />
            <HowItWorksLostPhone />
            <FeedbacksSectionNonLegal />
        </Box>
    );
};
