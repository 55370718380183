import React from "react";
import { NumberFormatBaseProps, NumericFormat } from "react-number-format";

interface NumberFormatCustomProps extends NumberFormatBaseProps {
    name: string;
}

const NumberFormatCustom = React.forwardRef<typeof NumericFormat, NumberFormatCustomProps>(function NumberFormatCustom(props, ref) {
    const { ...other } = props;

    return <NumericFormat getInputRef={ref} thousandSeparator {...other} />;
});

export default NumberFormatCustom;
