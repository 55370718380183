import { FC } from "react";
import { Button, Drawer, DrawerProps } from "@mui/material";
import { themeColors } from "constants/colors.const";

import { ReactComponent as CloseIcon } from "assets/icons/close-lightgrey.svg";
import { ReactComponent as BurgerIcon } from "assets/icons/burger-toggler-lightgrey.svg";

interface Props extends DrawerProps {
    onClose?: () => void;
    open: boolean;
}

export const BurgerMenu: FC<Props> = ({ sx, open, onClose, children, ...rest }) => {
    return (
        <>
            <Button
                style={{
                    padding: 0,
                    width: "44px",
                    height: "44px",
                    borderRadius: "10px",
                    background: "transparent",
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                    minWidth: "44px",
                }}
                onClick={onClose}
            >
                {open ? <CloseIcon /> : <BurgerIcon />}
            </Button>
            <Drawer
                anchor="top"
                open={open}
                onClose={onClose}
                sx={{
                    zIndex: 4,
                    ".MuiBackdrop-root": {
                        backdropFilter: "blur(2px)",
                    },
                    ".MuiDrawer-paper": {
                        padding: "14px 27px 24px",
                        backgroundColor: themeColors.background.grey,
                        width: "100%",
                        boxSizing: "border-box",
                    },
                    ...sx,
                }}
                {...rest}
            >
                {children}
            </Drawer>
        </>
    );
};
