import { useLocation } from "react-router-dom";
import { HeaderNavMenu } from "./NavMenu";
import { NAV_MENU_NON_LEGAL } from "./navMenu.const";
import { AppRoutes } from "enums/routes.enum";

export const NavMenuNonLegal = () => {
    const { pathname } = useLocation();
    const isLostPhonePage = pathname === AppRoutes.LOST_PHONE;

    return (
        <HeaderNavMenu
            menu={NAV_MENU_NON_LEGAL}
            ulStyle={{
                background: "transparent",
                marginTop: 0,
            }}
            liStyle={{
                color: "#fff",
            }}
            landingType={isLostPhonePage ? "lost my phone" : "locate by number non legal"}
        />
    );
};
