import { lazy, Suspense } from "react";
import { PageFallback } from "components/PageFallback";

let LostPhone = lazy(() => import("./LostPhone"));

export async function lazyLostPhoneLoader() {
    const componentModule = await import("./LostPhone");
    LostPhone = componentModule.default as any;

    return null;
}

export function LazyLostPhone() {
    return (
        <Suspense fallback={<PageFallback />}>
            <LostPhone />
        </Suspense>
    );
}
