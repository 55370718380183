import { Languages } from "enums/languages.enum";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { saveLanguage } from "helpers/language.helpers";
import { useEffect } from "react";
import { ampli } from "services/ampli";

export const useSidebarLogic = ({
    open,
    onClose,
    landing_type,
}: {
    open?: boolean;
    onClose: () => void;
    landing_type: "locate by number legal" | "locate by number non legal" | "lost my phone";
}) => {
    useEffect(() => {
        if (document.body && typeof open !== "undefined") {
            document.body.style.overflowY = open ? "hidden" : "unset";
        }
    }, [open]);

    const handleLanguagePick = (id: Languages, closeMenu?: boolean) => {
        saveLanguage(id.toUpperCase());
        if (closeMenu) {
            setTimeout(() => onClose(), 300);
        }
    };

    const handleMenuItemClick = (title: string) => {
        handleAmpliLoaded(() =>
            ampli.landingMenuClicked({
                chosen_navigation_item: title,
                landing_type,
            })
        );
        onClose();
    };

    return {
        handleLanguagePick,
        handleMenuItemClick,
    };
};
