import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import { useLocationsStore } from "providers/RootStoreProvider";
import { DesktopMenu, MobileMenu } from "./Menu";

const AccountLayout = observer(() => {
    const { fetchAllLocations } = useLocationsStore();

    useEffect(() => {
        fetchAllLocations();
    }, []);

    return (
        <Box minHeight="100svh" position="relative" display="flex" flexDirection="column">
            <DesktopMenu />
            <Outlet />
            <MobileMenu />
        </Box>
    );
});

export default AccountLayout;
