import { useEffect, useRef } from "react";

export const useExtendedAdornmentArea = () => {
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const clickHandler = () => {
            (inputRef.current?.querySelector(".MuiTelInput-IconButton") as HTMLButtonElement)?.click();
        };
        const elementAdornment = inputRef.current?.querySelector(".MuiInputAdornment-root");
        elementAdornment?.addEventListener("click", clickHandler);

        return () => elementAdornment?.removeEventListener("click", clickHandler);
    }, []);

    return inputRef;
};
