import { useDeviceDetect } from "hooks/useDeviceDetect";
import FeatureLinks from "./FeatureLinks";
import { MobileMenuContainer } from "./Menu.styled";

const MobileMenu = () => {
    const { isDesktop } = useDeviceDetect();

    if (isDesktop) {
        return null;
    }

    return (
        <MobileMenuContainer>
            <FeatureLinks variant="sm" />
        </MobileMenuContainer>
    );
};

export default MobileMenu;
