export const FEEDBACKS = [
    {
        id: "1",
        image: "/images/testimonials/user-mason.png",
        nickName: "Mason.48634",
        comment: "feedback_1",
    },
    {
        id: "2",
        image: "/images/testimonials/user-ava.png",
        nickName: "Ava284",
        comment: "feedback_2",
    },
    {
        id: "3",
        image: "/images/testimonials/user-emma.png",
        nickName: "EmmaL.",
        comment: "feedback_3",
    },
    {
        id: "4",
        image: "/images/testimonials/user-liam.png",
        nickName: "Liam9563",
        comment: "feedback_4",
    },
    {
        id: "5",
        image: "/images/testimonials/user-alexander.png",
        nickName: "AlexanderT.",
        comment: "feedback_5",
    },
    {
        id: "6",
        image: "/images/testimonials/user-noah.png",
        nickName: "Noah",
        comment: "feedback_6",
    },
];
