import { ReactNode, useRef } from "react";
import { WidthContainer } from "components/shared";
import { HeaderNavMenu } from "./NavMenu";
import { Link, useNavigate } from "react-router-dom";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { Sidebar } from "./Sidebar";
import { Box, Button, Stack, styled } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { SidebarTypes } from "enums/SidebarTypes.enum";
import { LanguagePicker } from "./LanguagePicker";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "enums/routes.enum";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { useLogout } from "hooks/useLogout";
import { useHeaderLogic } from "hooks/useHeaderLogic";
import { NAV_MENU } from "./navMenu.const";

interface Props {
    background?: string;
    customButton?: ReactNode;
    children?: ReactNode;
}

const StyledHeader = styled("header", {
    shouldForwardProp: () => true,
})<Props>(() => ({
    height: 0,
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    "& *": {
        flexShrink: 0,
    },
}));

export const HeaderLegal = () => {
    const navigate = useNavigate();
    const { isTablet } = useDeviceDetect();
    const { i18n, t } = useTranslation();
    const { user } = useUserStore();
    const { flowOuter } = useAppData();
    const logout = useLogout();

    const isLoggedIn = !!user?.email;
    const languagePickerRef = useRef<HTMLDivElement | null>(null);
    const sidebarRef = useRef<HTMLDivElement | null>(null);
    const sidebarTogglerRef = useRef<HTMLButtonElement | null>(null);

    const {
        handleCloseButtonClick,
        handleLanguagePickerClick,
        toggleSidebar,
        handleLogoClick,
        sidebar,
        isCloseButtonShown,
        isMenuTogglerShown,
        renderLoginButton,
        isHeaderScrolled,
        isHomePage,
        isAccountPage,
        isLegalPage,
        isPaymentPage,
        languagePickerOpen,
        setLanguagePickerOpen,
    } = useHeaderLogic({
        sidebarRef,
        sidebarTogglerRef,
        languagePickerRef,
    });

    return (
        <>
            <StyledHeader style={{ zIndex: isPaymentPage ? 5 : 10 }}>
                <WidthContainer>
                    <Link
                        to={isLoggedIn ? AppRoutes.ACCOUNT : AppRoutes.HOME}
                        onClick={handleLogoClick}
                        style={{
                            padding: "2px 10px",
                            borderRadius: "16px",
                            background:
                                isHeaderScrolled && !sidebar.isOpen && (isHomePage || isAccountPage || isLegalPage)
                                    ? themeColors.background.primary
                                    : "transparent",
                            boxSizing: "border-box",
                            height: "44px",
                            marginLeft: "-10px",
                            float: "left",
                            marginTop: isTablet ? "1rem" : "40px",
                        }}
                    >
                        <img
                            src={sidebar.isOpen || (!isHomePage && !isAccountPage && !isLegalPage) ? "/icons/logo-white-closer-dot.svg" : "/icons/Logo.svg"}
                            alt="Geomobile"
                        />
                    </Link>
                    {!isTablet && isHomePage && <HeaderNavMenu menu={NAV_MENU} />}
                    <Stack
                        gap="16px"
                        direction="row"
                        justifyContent="flex-end"
                        ref={languagePickerRef}
                        sx={{ float: "right", marginTop: isTablet ? "1rem" : "40px" }}
                    >
                        <Box sx={{ position: "relative" }}>
                            {flowOuter && (
                                <Button
                                    style={{
                                        padding: 0,
                                        width: "44px",
                                        height: "44px",
                                        borderRadius: "10px",
                                        background: themeColors.background.primary,
                                        color: themeColors.brandColors.brandGreen,
                                        border: `${isTablet ? "2px" : "1px"} solid ${themeColors.brandColors.brandLightgrey}`,
                                        fontSize: "16px",
                                        minWidth: "44px",
                                        fontWeight: 700,
                                    }}
                                    onClick={handleLanguagePickerClick}
                                >
                                    {i18n.language}
                                </Button>
                            )}
                            {languagePickerOpen && <LanguagePicker onClose={() => setLanguagePickerOpen(false)} />}
                        </Box>
                        {!isTablet && renderLoginButton && (
                            <Button
                                variant="themePrimarySmall"
                                sx={{ height: "44px", textTransform: "uppercase" }}
                                onClick={() => {
                                    if (user) {
                                        logout();
                                    } else {
                                        navigate(AppRoutes.LOG_IN);
                                    }
                                }}
                            >
                                {user ? t("Log out") : t("Log in")}
                            </Button>
                        )}
                        {isMenuTogglerShown && (
                            <Button
                                ref={sidebarTogglerRef}
                                style={{
                                    padding: 0,
                                    width: "44px",
                                    height: "44px",
                                    borderRadius: "10px",
                                    background: themeColors.background.primary,
                                    color: themeColors.brandColors.brandGreen,
                                    border: `${isTablet ? "2px" : "1px"} solid ${themeColors.brandColors.brandLightgrey}`,
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                    minWidth: "44px",
                                }}
                                onClick={() => toggleSidebar(SidebarTypes.Navigation)}
                            >
                                <img src={sidebar.isOpen ? "/icons/close.svg" : "/icons/burger-toggler.svg"} alt="" />
                            </Button>
                        )}
                        {isCloseButtonShown && (
                            <Button
                                style={{
                                    padding: 0,
                                    width: "44px",
                                    height: "44px",
                                    borderRadius: "10px",
                                    background: themeColors.background.primary,
                                    color: themeColors.brandColors.brandGreen,
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                    minWidth: "44px",
                                    border: `2px solid ${themeColors.brandColors.brandLightgrey}`,
                                }}
                                onClick={handleCloseButtonClick}
                            >
                                <img src="/icons/close.svg" alt="" />
                            </Button>
                        )}
                    </Stack>
                </WidthContainer>
            </StyledHeader>
            <Sidebar ref={sidebarRef} open={sidebar.isOpen} contenttype={sidebar.type} onClose={toggleSidebar} />
        </>
    );
};
