export const checkAbTest = (abTest: string, forAllFlows?: boolean) => {
    const flowOuter = localStorage.getItem("flowOuter");
    if ((flowOuter && !forAllFlows) || forAllFlows) {
        const abTestData = localStorage.getItem("abTestData");
        if (abTestData) {
            const testValue = JSON.parse(abTestData)[abTest];
            return testValue;
        }
    }

    return false;
};
