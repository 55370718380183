import { FC } from "react";
import { MenuProps, SxProps } from "@mui/material";
import { observer } from "mobx-react-lite";
import { MuiTelInput } from "mui-tel-input";
import { useExtendedAdornmentArea } from "./hooks";
import { themeFonts } from "mui-theme/themeFonts.const";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { themeColors } from "constants/colors.const";
import { TelephoneInputProps } from "./TelephoneInput.types";

export const TelephoneInput: FC<TelephoneInputProps> = observer(({ loading, ...props }) => {
    const { isMobile } = useDeviceDetect();
    const inputRef = useExtendedAdornmentArea();

    const muiInputSx: SxProps = {
        mb: "1rem",
        borderRadius: "1rem",
        width: isMobile ? "100%" : "350px",
        "& .MuiTypography-root": {
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "20px",
            fontWeight: "500",
            border: "none",
        },
        "& input": {
            p: "19px 20px 19px 0px",
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: themeFonts.sourceCodePro,
            lineHeight: "25px",
            color: !props.error ? "#0C0F21" : themeColors.state.error,
            caretColor: themeColors.brandColors.brandGreen,
        },
        "& .MuiInputBase-input": {
            color: themeColors.text.white,
        },
        "& .MuiInputBase-root": {
            height: "67px",
            borderRadius: "1rem",
            bgcolor: themeColors.brandColors.brandBlack,
            border: "2px solid rgba(255, 255, 255, .4)",
            "& ::placeholder": { color: themeColors.text.grey, opacity: "1" },
            "&.Mui-focused": {
                borderColor: themeColors.brandColors.brandGreen,
                "& fieldset": {
                    borderColor: "transparent",
                },
            },
            "&.Mui-error": {
                borderColor: themeColors.state.error,
                ".MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
                "& input": {
                    color: themeColors.state.error,
                    caretColor: themeColors.state.error,
                },
            },
        },
        "& .MuiInputAdornment-root": {
            position: "relative",
            mr: "0px",
            color: themeColors.text.white,
            fontSize: "22px",
            height: "100%",
            visibility: loading ? "hidden" : "visible",
        },
        "& .MuiTelInput-Flag img": { minHeight: "24px", maxWidth: "unset" },
        "& .MuiInputAdornment-root:after": {
            content: '""',
            display: "block",
            position: "absolute",
            cursor: "pointer",
            inset: "-20px 0 -20px -14px",
        },
        "& .MuiInputAdornment-root:before": {
            content: "url(/icons/arrow.svg)",
            position: "absolute",
            right: "4px",
            top: "50%",
            transform: "translateY(-40%)",
        },
        "& .MuiTelInput-IconButton": {
            visibility: loading ? "hidden" : "visible",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            overflow: "hidden",
            mr: "8px",
        },
        "& .MuiInputAdornment-root .MuiTypography-root": {
            pr: "23px",
        },
        "& .MuiOutlinedInput-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #fff inset",
            WebkitTextFillColor: "#0C0F21",
        },
        "& .Mui-disabled": {
            borderRadius: "1rem",
            "& .MuiTypography-root": {
                color: themeColors.text.grey,
            },
            "& input": {
                boxSizing: "border-box",
            },
            "&::after": {
                position: "absolute",
                content: "''",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                background: "rgba(255, 255, 255, .3)",
                borderRadius: ".9rem",
            },
            ".MuiInputAdornment-root": { mr: "0px" },
            "& .MuiInputAdornment-root:before": {
                content: "url(/icons/arrow.svg)",
                position: "absolute",
                right: "4px",
                top: "50%",
                transform: "translateY(-40%)",
                filter: "invert(30%)",
            },
            fieldset: {
                border: "none",
            },
        },
        "& .MuiFormHelperText-root": {
            mt: "6px",
            mb: "6px",
            fontWeight: "300",
            lineHeight: "15px",
            color: themeColors.state.error,
            fontFamily: themeFonts.sourceCodePro,
            fontSize: "1rem",
            "& .Mui-error": {
                color: themeColors.state.error,
            },
        },
    };

    const muiMenuProps: Partial<MenuProps> = {
        sx: {
            "& .MuiPaper-root": {
                maxHeight: "300px",
                border: `2px solid ${themeColors.brandColors.brandGreen}`,
                transitionDuration: "200ms !important",
                scrollbarWidth: "none",
                color: "#0C0F21",
                background: themeColors.background.secondary,
                marginTop: ".25rem",
                maxWidth: isMobile ? "100%" : "350px",
                borderRadius: "20px",
            },
            "& ::-webkit-scrollbar": {
                display: "none",
            },
            "& .MuiTelInput-Typography-calling-code": {
                color: "#72789A",
                fontSize: "18px",
                fontWeight: "300",
            },
            "& .MuiTelInput-ListItemText-country": {
                "& .MuiTypography-root": {
                    fontSize: "18px",
                    fontWeight: "500",
                },
            },
            "& .MuiTelInput-MenuItem.Mui-selected, & .MuiTelInput-MenuItem.Mui-selected:hover": {
                bgcolor: "rgba(237, 237, 249, 1)",
                width: "calc(100% - 0.5px)",
            },
            "& .MuiButtonBase-root, & .MuiTypography-body1": {
                color: themeColors.text.white,
                fontWeight: 700,
                fontFamily: themeFonts.salara,
                "&.Mui-selected": {
                    background: themeColors.background.whiteOpacity4,
                },
                "& .MuiTypography-body2": {
                    color: themeColors.text.grey,
                },
                "& .MuiListItemIcon-root span": {
                    width: "1.5rem",
                    height: "1.5rem",
                    borderRadius: "50%",
                    overflow: "hidden",
                    "& img, & picture": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    },
                },
            },
        },
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
    };

    return (
        <MuiTelInput
            ref={inputRef}
            sx={muiInputSx}
            MenuProps={muiMenuProps}
            inputProps={{
                maxLength: 13,
            }}
            {...props}
        />
    );
});
