import { AppRoutes } from "enums/routes.enum";

export const NAV_MENU = [
    {
        id: "1",
        title: "Features",
        href: "/#features",
    },
    {
        id: "2",
        title: "How it works",
        href: "/#how-it-works",
    },
    {
        id: "3",
        title: "Contacts",
        href: "#contacts",
    },
];

export const NAV_MENU_NON_LEGAL = [
    {
        id: "1",
        title: "Features",
        href: "#features",
        children: [
            { title: "Locate by number", href: AppRoutes.HOME },
            { title: "Find lost phone", href: AppRoutes.HOME_LOST_PHONE },
        ],
    },
    {
        id: "2",
        title: "Feedbacks",
        href: "#feedbacks",
    },
    {
        id: "3",
        title: "How it works",
        href: "#how-it-works",
    },
    {
        id: "4",
        title: "Contacts",
        href: "#contacts",
    },
];

export const NAV_MENU_NON_LEGAL_MOBILE = [
    {
        id: "1",
        title: "Features",
        href: "#features",
        children: [
            { title: "Locate by number", href: AppRoutes.HOME },
            { title: "Find lost phone", href: AppRoutes.HOME_LOST_PHONE },
        ],
    },
    {
        id: "3",
        title: "How it works",
        href: "#how-it-works",
    },
];
