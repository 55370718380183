import { Box, Typography } from "@mui/material";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { themeColors } from "constants/colors.const";
import { useTranslation } from "react-i18next";
import { TestimonialsSlider } from "./TestimonialsSlider";

export const FeedbacksSectionNonLegal = () => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useDeviceDetect();

    const slidesCount = isMobile ? 1.3 : isTablet ? 3 : 4;

    return (
        <Box sx={{ padding: { xs: "0 0 40px", sm: "0 0 120px" } }} id="feedbacks">
            <Typography variant="h3" color={themeColors.text.fullBlack} textAlign="center" mb="40px">
                {t("Hear from real")} {isMobile && <br />}
                <Typography variant="title1Bold" color={themeColors.brandColors.brandGreen} display="inline" fontSize="34px" lineHeight="42px">
                    {t("Geomobile Users")}
                </Typography>
            </Typography>
            <TestimonialsSlider slidesPerView={slidesCount} centeredSlides isTransparent={isMobile} />
        </Box>
    );
};
