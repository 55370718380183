import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Stack, Typography, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FEEDBACKS } from "./feedbacks.model";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { themeColors } from "constants/colors.const";
import { useTranslation } from "react-i18next";
import { SwiperOptions } from "swiper/types/swiper-options";

interface TestimonialCardProps {
    image: string;
    nickName: string;
    comment: string;
    isTransparent?: boolean;
}

const StyledTestimonialCardWrap = styled(Stack, {
    shouldForwardProp: () => true,
})<{ isTransparent?: boolean }>(({ theme, isTransparent }) => ({
    padding: "24px",
    boxSizing: "border-box",
    background: isTransparent ? "transparent" : theme.palette.background.quaternary,
    borderRadius: "16px",
    boxShadow: "0 0 14px 0 rgba(0, 0, 0, .05)",
    height: "100%",
    border: isTransparent ? `1px solid ${theme.palette.brandColors.brandGreen}` : "unset",
    "& .five-stars-img": {
        height: "16px !important",
    },
}));

const StyledSwiperPagination = styled(Stack)(({ theme }) => ({
    position: "static",
    "& div": {
        width: "12px",
        height: "12px",
        background: theme.palette.brandColors.brandGreen,
        borderRadius: "2px",
    },
}));

const StyledSwiperNavigation = styled(Stack)(({ theme }) => ({
    position: "static",
    ".swiper-button-prev, .swiper-button-next": {
        position: "static",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.brandColors.brandGreen,
        borderRadius: "10px",
        margin: 0,
        zIndex: 1,
        "&:after": {
            display: "none",
        },
        "& img": {
            width: "24px",
        },
    },
}));

const TestimonialCard: FC<TestimonialCardProps> = ({ image, nickName, comment, isTransparent }) => {
    return (
        <StyledTestimonialCardWrap isTransparent={isTransparent}>
            <Stack direction="row" gap="12px" sx={{ mb: "20px" }}>
                <img src={image} alt={nickName} width="64px" />
                <Stack direction="column" alignItems="flex-start">
                    <Typography variant="title1Bold" sx={{ marginBottom: "4px" }}>
                        {nickName}
                    </Typography>
                    <img src="/icons/five-stars.svg" className="five-stars-img" />
                </Stack>
            </Stack>
            <Typography variant="smallRegular" color={themeColors.text.quaternary}>
                {comment}
            </Typography>
        </StyledTestimonialCardWrap>
    );
};

export const SwiperPagination = () => <StyledSwiperPagination className="swiper-pagination" direction="row" alignItems="center" justifyContent="center" />;

export const SwiperNavigation = ({ isMobile }: { isMobile: boolean }) => (
    <StyledSwiperNavigation direction="row" gap="16px" justifyContent={isMobile ? "center" : "flex-end"}>
        <div className="swiper-button-prev">
            <img src="/icons/arrow-back-white.svg" alt="" />
        </div>
        <div className="swiper-button-next">
            <img src="/icons/arrow-forvard-white.svg" alt="" />
        </div>
    </StyledSwiperNavigation>
);

interface TestimonialsSlider extends SwiperOptions {
    isTransparent?: boolean;
}

export const TestimonialsSlider: FC<TestimonialsSlider> = ({ isTransparent, ...props }) => {
    const { t, i18n } = useTranslation();
    const [langKey, setLangKey] = useState<string | undefined>(undefined);

    useEffect(() => {
        setLangKey(i18n.language);
    }, [i18n.language]);

    return (
        <Swiper
            key={langKey}
            loop={true}
            slidesPerView={props.slidesPerView}
            spaceBetween={20}
            pagination={{
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (_, className) {
                    return `<div class="${className}"></div>`;
                },
            }}
            navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            }}
            modules={[Autoplay, Pagination, Navigation]}
            autoHeight
            autoplay={{
                delay: 2000,
                disableOnInteraction: false,
            }}
            speed={700}
            {...props}
        >
            {FEEDBACKS.map((slide, index) => {
                return (
                    <SwiperSlide key={index} style={{ alignSelf: "stretch" }}>
                        <TestimonialCard image={slide.image} nickName={slide.nickName} comment={t(slide.comment)} isTransparent={isTransparent} />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};
