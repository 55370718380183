import { useScrollEvent } from "hooks/useScrollEvent";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { useEffect } from "react";
import { ampli } from "services/ampli";
import { checkAbTest } from "helpers/checkAbTest";

export const useLandingEvents = ({ landing_type }: { landing_type: string }) => {
    const webAttributionSent = !!localStorage.getItem("attributionEventSent");
    useScrollEvent(() => {
        handleAmpliLoaded(() => ampli.landingScroll({ landing_type }));
    });

    useEffect(() => {
        if (ampli.isLoaded && webAttributionSent) {
            const ab_paywall = checkAbTest("abTestPaywall", true);
            const params = {
                ab_paywall,
                landing_type,
            };
            ampli.landingScreenShown(params);
        }
    }, [ampli.isLoaded, webAttributionSent]);
};
